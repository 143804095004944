import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppComponent } from '../app.component';

@Injectable({
  providedIn: 'root'
})
export class CierresGuardiaService {
  baseUrl: string;

  constructor(
    private http: HttpClient) {      
        this.baseUrl = AppComponent.baseUrl;
    }

    getHistorialCierresGuardia(){
      return this.http.get<any>(this.baseUrl+'/cierresGuardia/getCierresGuardia');
    }

    setCierreDeGuardia(body){
      const headers = { 
        'Content-Type': 'application/json; charset=UTF-8' 
      };
      return this.http.post<any>(this.baseUrl+'/cierresGuardia/seguro/setCierreGuardia', body, { headers });
    }

    getMotivosDeCierre(){
      return this.http.get<any>(this.baseUrl+'/cierresGuardia/getMotivosCierreGuardia');
    }
}
