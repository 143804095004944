import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { ErrorInterceptor } from './_helpers/error.interceptor';
import { JwtInterceptor } from './_helpers/jwt.interceptor';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatDividerModule} from '@angular/material/divider';
import { FooterComponent } from './footer/footer.component';  
import { LoginComponent } from './login/login.component';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BuscarPacienteDialog, CrearPacienteDialog } from './triage-enfermeria/triage-enfermeria.component';



import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { toTitleCasePipe } from './_pipes/toTitleCase.pipe';
import { safeUrlPipe } from './_pipes/safeUrl.pipe'
import { toLocalDatePipe } from './_pipes/toLocalDate.pipe'
import { FilterPipe } from './_pipes/filter.pipe'

//import { FlexLayoutModule } from '@angular/flex-layout';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';


import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ManagerComponent } from './manager/manager.component';
import { InternadosComponent } from './internados/internados.component';
import { ReportesComponent } from './reportes/reportes.component';
import { SeguidosComponent } from './seguidos/seguidos.component';
import { AlertComponent } from './alert/alert.component';
import { PacienteComponent } from './paciente/paciente.component';
import { HistorialSignosVitalesComponent } from './historial-signos-vitales/historial-signos-vitales.component';
import { HistorialEvolucionEnfermeriaComponent } from './historial-evolucion-enfermeria/historial-evolucion-enfermeria.component';
import { HistorialIndicacionMedicaComponent } from './historial-indicacion-medica/historial-indicacion-medica.component';
import { DialogConfirmacionComponent } from './dialog-confirmacion/dialog-confirmacion.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MenuComponent } from './menu/menu.component';
import { TriageEnfermeriaComponent } from './triage-enfermeria/triage-enfermeria.component';

import { CrearTriageComponent } from './crear-triage/crear-triage.component';
import { SignosVitalesComponent } from './signos-vitales/signos-vitales.component';
import { SintomasPacienteComponent } from './sintomas-paciente/sintomas-paciente.component';
import { DialogDatosGuardadosComponent } from './dialog-datos-guardados/dialog-datos-guardados.component';
import { VisualizacionPacienteTriageComponent } from './visualizacion-paciente-triage/visualizacion-paciente-triage.component';
import { DialogMensajeComponent } from './dialog-mensaje/dialog-mensaje.component';

import { CamasComponent } from './camas/camas.component';
import { HomeComponent } from './home/home.component';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { DialogTrasladoPacienteComponent } from './dialog-traslado-paciente/dialog-traslado-paciente.component';
import { PacientesParaInternarComponent } from './pacientes-para-internar/pacientes-para-internar.component';
import { DialogAsignacionCamaComponent } from './dialog-asignacion-cama/dialog-asignacion-cama.component';
import { EstudiosPendientesPorPabellonComponent } from './estudios-pendientes-por-pabellon/estudios-pendientes-por-pabellon.component';
import { CajasCirugiasComponent } from './cajas-cirugias/cajas-cirugias.component';
import { ReservaCajasComponent } from './reserva-cajas/reserva-cajas.component';
import { DialogModificarCajaComponent } from './dialog-modificar-caja/dialog-modificar-caja.component';
import { CirugiasProgramadasComponent } from './cirugias-programadas/cirugias-programadas.component';
import { DialogAsignacionCajaComponent } from './dialog-asignacion-caja/dialog-asignacion-caja.component';
import { DialogRecuperarContrasenaComponent } from './dialog-recuperar-contrasena/dialog-recuperar-contrasena.component';
import { DialogCambiarContrasenaComponent } from './dialog-cambiar-contrasena/dialog-cambiar-contrasena.component';
import { GuardiasCierreComponent } from './guardias-cierre/guardias-cierre.component';
import { GuardiasCierreModalComponent } from './guardias-cierre-modal/guardias-cierre-modal.component';
import { RecortarFechaPipe } from './_pipes/recortar-fecha.pipe';
import { MostrarMotivoPipe } from './_pipes/mostrar-motivo.pipe';



@NgModule({
    declarations: [
        AppComponent,
        toTitleCasePipe,
        safeUrlPipe,
        FilterPipe,
        toLocalDatePipe,
        FooterComponent,
        LoginComponent,
        ManagerComponent,
        InternadosComponent,
        ReportesComponent,
        SeguidosComponent,
        AlertComponent,
        PacienteComponent,
        HistorialSignosVitalesComponent,
        HistorialEvolucionEnfermeriaComponent,
        HistorialIndicacionMedicaComponent,
        DialogConfirmacionComponent,
        BuscarPacienteDialog,
        CrearPacienteDialog,
        MenuComponent,
        TriageEnfermeriaComponent,
        CrearTriageComponent,
        SignosVitalesComponent,
        SintomasPacienteComponent,
        DialogDatosGuardadosComponent,
        VisualizacionPacienteTriageComponent,
        DialogMensajeComponent,
        CamasComponent,
        HomeComponent,
        DialogTrasladoPacienteComponent,
        PacientesParaInternarComponent,
        DialogAsignacionCamaComponent,
        CajasCirugiasComponent,
        ReservaCajasComponent,
        DialogModificarCajaComponent,
        EstudiosPendientesPorPabellonComponent,
        CirugiasProgramadasComponent,
        DialogAsignacionCajaComponent,
        DialogRecuperarContrasenaComponent,
        DialogCambiarContrasenaComponent,
        GuardiasCierreComponent,
        GuardiasCierreModalComponent,
        RecortarFechaPipe,
        MostrarMotivoPipe
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        MatExpansionModule,
        //FlexLayoutModule,
        FormsModule, ReactiveFormsModule,
        MatCheckboxModule,
        CommonModule,
        MatButtonModule,
        MatMenuModule,
        MatToolbarModule,
        MatIconModule,
        MatCardModule,
        MatSidenavModule,
        MatBadgeModule,
        MatListModule,
        MatGridListModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatRadioModule,
        MatDialogModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatChipsModule,
        MatTooltipModule,
        MatTableModule,
        MatPaginatorModule,
        MatDividerModule,
        MatStepperModule,
        MatTabsModule,
        NgxMaterialTimepickerModule,
        NgxMatSelectSearchModule,
        MatSlideToggleModule,
        ReactiveFormsModule
    ],
    exports: [
        MatButtonModule,
        MatToolbarModule,
        MatMenuModule,
        MatIconModule,
        MatSidenavModule,
        MatProgressBarModule,
        MatExpansionModule,
        MatProgressSpinnerModule,
        MatCheckboxModule,
        MatBadgeModule,
        MatListModule,
        MatGridListModule,
        MatInputModule,
        MatFormFieldModule,
        MatDialogModule,
        MatSelectModule,
        MatRadioModule,
        MatDatepickerModule,
        MatChipsModule,
        MatTooltipModule,
        MatTableModule,
        MatPaginatorModule,
        MatTabsModule,
        NgxMaterialTimepickerModule,
        CrearTriageComponent,
        SignosVitalesComponent,
        SintomasPacienteComponent,
    ],
    providers: [
        MatDatepickerModule, SignosVitalesComponent, CrearTriageComponent,
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: MAT_DATE_LOCALE, useValue: 'es-ES' }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
