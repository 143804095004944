import { Pipe, PipeTransform } from '@angular/core';
import { CierreGuardia } from '../guardias-cierre/guardias-cierre.component';
import { CharactersService } from '../_services/characters.service';

@Pipe({
  name: 'mostrarMotivo'
})
export class MostrarMotivoPipe implements PipeTransform {

  constructor(private charactersService: CharactersService) {}

  transform(cierre: CierreGuardia): string {
    let descripcionFinal;
    if (cierre.motivo.id === 1) {
      descripcionFinal = cierre.motivoDescripcion;
    } else {
      descripcionFinal = cierre.motivo.descripcion;
    }
    return this.charactersService.reconvertirCaracteresEspeciales(descripcionFinal);
  }
  

}
