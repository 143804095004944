import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'recortarFecha'
})
export class RecortarFechaPipe implements PipeTransform {
  transform(value: string): string {
    if (!value || value.includes("01/01/1900")) return '';
    
    const [fecha, hora] = value.split(' ');
    const horaConMinutos = hora ? hora.substring(0, 5) : '';
    return `${fecha} ${horaConMinutos}`;
  }
}
