/*@matiasmo */
import { Component, EventEmitter, Input, OnInit,Output,ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { PacienteService } from '../_services/paciente.service';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { throwToolbarMixedModesError } from '@angular/material/toolbar';
import { DialogMensajeComponent } from '../dialog-mensaje/dialog-mensaje.component';

import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
//import { ValueTransformer } from '@angular/compiler/src/util';
import { AuthenticationService } from '../_services/authentication.service';
import { CharactersService } from '../_services/characters.service';
import { TriageService } from '../_services/triage-paciente.service';
@Component({
  selector: 'app-crear-triage',
  templateUrl: './crear-triage.component.html',
  styleUrls: ['./crear-triage.component.css'],
  
})

export class CrearTriageComponent implements OnInit {
  
  //checkbox
  checked = false;
  indeterminate = false;
  labelPosition = 'after';
  disabled = false;
  prioridadSeleccionadaModel:any;
  //
  listaCategorias:any[];
  listaSubCategorias:any[];
  prioridadSugerida;
  impresionForm: UntypedFormGroup = new UntypedFormGroup({
    idTriage: new UntypedFormControl(),
    catTriage:new UntypedFormControl(),
    subcatTriage:new UntypedFormControl(),
    hora:new UntypedFormControl(),
    fecha:new UntypedFormControl(),
    codigoServicio:new UntypedFormControl(),
    presionSis: new UntypedFormControl(),
    presionDias: new UntypedFormControl(),
    pulsoCardiaco:new UntypedFormControl(),
    frecRespiratoria: new UntypedFormControl(),
    tempCorporal:new UntypedFormControl(),
    saturacion: new UntypedFormControl(),
    glasgow:new UntypedFormControl(),
    paciente:new UntypedFormControl(),
    observacion:new UntypedFormControl(),
    prioridadSeleccionada: new UntypedFormControl(),
    servicio:new UntypedFormControl(),
    respiratorio:new UntypedFormControl(),
    modoArribo: new UntypedFormControl(),
    medico:new UntypedFormControl(),
    color:new UntypedFormControl(),
    enfermero:new UntypedFormControl(),
    estado:new UntypedFormControl()
  });
  submitted: boolean = false;
  loading: boolean = false;
  currentPatient: any;
  currentUser;
  patientData: any;
  esUrgencia: boolean;
  time = new Date();
  //servicios filtrados (sin afecciones respiratorias)
  serviciosFilt: any[] = [];

  fechaCompleta=  this.time.toLocaleDateString();
  horaCompleta = this.time.toLocaleTimeString('en-US', { 
    hour: '2-digit', 
    minute: '2-digit', 
    second: '2-digit',
    hour12: false // Esto asegura el formato de 24 horas
  });      
  
  //respiratorios
  respiratorios=[
    { value: 'Si ' },
    { value: 'No'  }
  ]
  //filtro select
  subcategorias: any[];
  filterCtrl: UntypedFormControl = new UntypedFormControl();
  subcatFiltradas : any[];
   //filtro medicos
   medicos: any[];
   filtermedCtrl: UntypedFormControl = new UntypedFormControl();
   medFiltrados: any[];
  
  // servicios
  listaServicios:any[];
  listaArribos:any[];
  //listado de profesionales
  profesionales:any[];
  protected onDestroyProperty = new Subject<void>();

  //mat-expansion-panel
  panelOpen = false;
  loadingTriage = false;
  
  constructor(private formBuilder: UntypedFormBuilder,
    private pacienteService: PacienteService,
    private router: Router,
    public dialog: MatDialog,
    private auth: AuthenticationService,
    private charactersServices: CharactersService,
    private triageService: TriageService
   
    
    ) 
  { 
    this.currentPatient = this.pacienteService.getCurrentPatientLocal(); 
    this.currentUser = this.auth.currentUserValue;
    // console.log(this.currentUser.nombreCompleto)
  }
 
 async ngOnInit() {
    
    
    this.cargarPaciente();
    await this.getTipoCategoria();
    await this.buildFormImpresionInicial();
    await this.getSubCategorias(this.impresionForm.get('catTriage').value);
    
    this.listenFilter();
    this.obtenerServicios();
    this.obtenerModoArribo();
    this.listarProfesionales();
    this.listenmedFilter();
    
  }
  
  async  buildFormImpresionInicial() {
    this.impresionForm = this.formBuilder.group({
      idTriage:['-1'],
      catTriage:[this.listaCategorias.find(((categoria)=> categoria.nombre === 'Impresion Inicial'))],
      subcatTriage:[{
        nombreSubcat:'No especificado',
        idSubCat:'',
        prioridad:{
          idPrioridad:''
        }
      }],
      hora:[this.horaCompleta],
      fecha:[this.fechaCompleta],
      codigoServicio:['189'],
      presionSis: [null],
      presionDias: [null],
      pulsoCardiaco:[null],
      frecRespiratoria: [null],
      tempCorporal:[null],
      saturacion: [null],
      glasgow:[null],
      paciente:{
        nroDocumento : this.currentPatient.nroDocumento,
        tipoDocumento : this.currentPatient.tipoDocumento

       },
       observacion:[null],
       prioridadSeleccionada: [null,[Validators.required]],
       servicio:[null,[Validators.required]],
       respiratorio:[null,[Validators.required]],
       modoArribo: [{
        nombre:'',  
        id: ''
       }],
       medico:{
        apellido:'',
        matricula:[null]
      
      },
       color:[
        {
          idColor:'',
          nombre:''
        }
       ],
       enfermero:[this.currentUser],
       estado:{
        estado: null,
        desc: ''
       }
       
      });
      };
    getErrorMessage(Campo) {
      return this.impresionForm.get(Campo).hasError('required') ? 'Este campo es obligatorio' : '';
    }

    volver() {
       this.pacienteService.removeCurrentPatient();
        this.router.navigate(['/triageEnfermeria']);
     }

        
    cargarPaciente(){
      this.pacienteService.datosPaciente(this.currentPatient.nroDocumento,this.currentPatient.tipoDocumento).subscribe(
                (data2) => {
                  this.currentPatient = data2;
                 }, 
              );
          (error) => {
            console.log(error);
          }    
         };
    

     prioridadSintoma(cat){
       let categoria;

       if(cat.prioridad.idPrioridad == 1){
         categoria = cat;
         this.esUrgencia = true;

       }
       else {
         categoria = cat;
         this.esUrgencia = false;

       }
       this.pacienteService.setCurrentPatientLocal(this.currentPatient);
       
      return categoria;
     }
     //redirecciono a la siguiente vista
     signosVitales(paciente){
       this.pacienteService.setCurrentPatientLocal(paciente);
       this.pacienteService.setCurrentSignosFormLocal(this.impresionForm);
     }

    //categorias para el form
    async getTipoCategoria() {
      await this.triageService.cargarCategorias().toPromise().then(resp => {
        if(resp.ok){
        this.listaCategorias = resp.elementos;
        
        }
      }, error => {
        console.log(error)
      })
    }
    async getSubCategorias(categoria){
      await this.triageService.cargarSubCategorias(categoria.id).toPromise().then((resp)=>{
        if(resp.ok){
        this.listaSubCategorias= resp.elementos;
        this.subcatFiltradas = resp.elementos;
        this.subcatFiltradas.forEach(elem=>{
        
          if(elem.nombreSubcat === 'No especificado'){
              this.impresionForm.value.subcatTriage.nombreSubcat = elem.nombreSubcat;
              this.impresionForm.value.subcatTriage.idSubCat = elem.idSubCat;
              this.impresionForm.value.subcatTriage.prioridad.idPrioridad = elem.prioridad.idPrioridad;
          }
          
        })
        }
      }, error => {
        console.log(error)
      })
    }

    finalizarTriage(){
          
          this.submitted = true;
          if (!this.impresionForm.valid) {
            return;
          }
          this.impresionForm.value.observacion = this.charactersServices
                              .escaparCaracteresEspeciales(this.impresionForm.value.observacion);
          this.impresionForm.value.subcatTriage.prioridad.idPrioridad = this.impresionForm.get('prioridadSeleccionada').value; //aca pongo como valor la prioridad que elije el enfermero 
          this.colorSeleccionado(this.impresionForm.value.subcatTriage.prioridad.idPrioridad);
          this.pacienteService.setCurrentSignosFormLocal(this.impresionForm);
          this.chequeoPanel();
          this.loadingTriage = true;
          // console.log(this.impresionForm.value);
          if (this.impresionForm.value.tempCorporal < 30 || this.impresionForm.value.tempCorporal > 45) {
            this.dialog.open(DialogMensajeComponent,{
              width: '500px',
              data:{
                ok: false,
                mensaje: "La temperatura corporal debe encontrarse en un rango de entre 30 y 45 grados"
              }
            }).afterClosed().subscribe(()=>{
              this.loadingTriage = false;
          })
          } else {
            this.pacienteService
            .finTriage(this.impresionForm.value).subscribe(
              (data) => {
                  if (data.ok) {
                    this.loadingTriage = false;
                    this.submitted=false;
                    this.dialog.open(DialogMensajeComponent,{
                      width: '500px',
                      data:{
                        ok: true,
                        mensaje: "Triage cargado correctamente"
                      }
                    }).afterClosed().subscribe(()=>{
                        
                        this.router.navigate(['/triageEnfermeria']);
                    })
                    
                  }
                  
                  },
              (error) => {
                console.log(error);
                
              }
            );
          }
            
    }
    edadPaciente(e){
      return this.pacienteService.edadPaciente(e) 
      }
   abrirDialogMensaje(resp) {
    const dialogRef = this.dialog.open(DialogMensajeComponent, {
      width: '500px',
      height: '100px',
      data: resp,
      
    });
    dialogRef.afterClosed().subscribe(() => {
      if (resp.ok) {        
        // this.router.navigate(['/visualizacion-paciente-triage']);

      }
    });
  }
 
  listenFilter() {
    this.filterCtrl.valueChanges
      .pipe(takeUntil(this.onDestroyProperty))
      .subscribe(() => {
        this.filtrosubCategorias(); 
      });
  }
  protected filtrosubCategorias() {
    if (!this.listaSubCategorias) {
      return;
    }
    let search = this.filterCtrl.value;
    
    if (!search) {
      this.subcatFiltradas = this.listaSubCategorias;
      return;
    } else {
      search = search.toLowerCase();
    }
    this.subcatFiltradas =
      this.listaSubCategorias.filter(
        (subcategorias) => subcategorias.nombreSubcat.toLowerCase().indexOf(search) > -1
       );
      
  }

 async obtenerServicios(){
    await this.triageService.cargarServicios().toPromise().then((resp)=>{
     
      if(resp.ok){
      this.listaServicios= resp.elementos;
      // console.log(this.listaServicios)
      //ESTE FILTRO ES PARA QUE NO APAREZCA EL SERVICIO DE AFECCIONES RESPIRATORIAS(SOLUCION TEMPORAL)
      this.listaServicios.forEach(((elem,indice) =>{

        if(elem.nombre != 'Afecciones respirato'){
         
          this.serviciosFilt[indice - 1] = elem; //LE RESTO UNO PARA QUE LA POSICION 0 NO QUEDE VACIA
        }
      

      }))
      // console.log(this.serviciosFilt)

      }
    }, error => {
      console.log(error)
    })
  }
  async obtenerModoArribo(){
    await this.triageService.cargarArribos().toPromise().then((resp)=>{
      if(resp.ok){
        this.listaArribos= resp.elementos;
        }
      }, error => {
        console.log(error)
      })
  }
 async listarProfesionales(){
  let matricula= null;
  let servicio  = null;
  let especialidad= null;
  let condicion = null;
  let estado = 'ON';
  
  await this.pacienteService.getProfesional(matricula,servicio ,especialidad , condicion , estado).subscribe(
      (data) => {
        this.profesionales = data.paginador.elementos;
        this.medFiltrados = data.paginador.elementos;
       
      },
      (error) => {
        console.log(error);
      }
    )
  }

  colorSeleccionado(prioridad){
    switch(prioridad){

         case 1: this.impresionForm.value.color.idColor= 1;
                 this.impresionForm.value.color.nombre = "rojo"
          break;

         case 2: this.impresionForm.value.color.idColor= 2;
                 this.impresionForm.value.color.nombre = "amarillo"
          break;

         case 3: this.impresionForm.value.color.idColor = 3;
                 this.impresionForm.value.color.nombre = "verde"
          break;
    }

  }
  filtroMedicos(){
    if (!this.profesionales) {
      return;
    }
    let search = this.filtermedCtrl.value;
    
    if (!search) {
      this.medFiltrados = this.profesionales;
      return;
    } else {
      search = search.toLowerCase();
    }
   
    this.medFiltrados =
      this.profesionales.filter(
        (medicos) => medicos.apellido.toLowerCase().indexOf(search) > -1 || medicos.nroMatricula.indexOf(search) > -1
      );


  }
  listenmedFilter() {
    this.filtermedCtrl.valueChanges
      .pipe(takeUntil(this.onDestroyProperty))
      .subscribe(() => {
        this.filtroMedicos();
        
      });

    
  }
  chequeoPanel(){
    if(!this.panelOpen){
      this.panelOpen = true;
    }
  }



 }
