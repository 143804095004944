<div class="modal-background">
  <div class="modal-content">
    <h2>Seleccionar motivo de cierre</h2>
    <label for="motivo">Motivo:</label>
    <select id="motivo" [(ngModel)]="selectedMotivo">
      <option *ngIf="!editar" [value]="null" disabled selected>Seleccionar</option>
      <option *ngIf="editar" disabled selected [ngValue]="cierre.motivo">
        {{ cierre.motivo.descripcion }}
      </option>
      <option *ngFor="let motivo of motivos" [ngValue]="motivo">
        {{ motivo.descripcion }}
      </option>
    </select>

    <div *ngIf="selectedMotivo && (selectedMotivo.descripcion == 'OTRO')">
      <label for="descripcion">Descripción:</label><br>
      <input type="text" id="descripcion" [(ngModel)]="descripcion" placeholder="Escribe una descripción"
      (input)="sanitizeInput()"/>
    </div>

    <div *ngIf="error" class="error-message">
      <p>{{ mensaje }}</p>
    </div>

    <div class="modal-actions">
      <button class="btn btn-danger" (click)="cerrar()">Cancelar</button>
      <button class="btn btn-success" (click)="confirmar()">Confirmar</button>
    </div>
  </div>
</div>
