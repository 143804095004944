import { Component, Output, EventEmitter, Input } from '@angular/core';
import { CierresGuardiaService } from '../_services/cierres-guardia.service'
import { CierreGuardia, GuardiasCierreComponent } from '../guardias-cierre/guardias-cierre.component';
import { CharactersService } from '../_services/characters.service';

export interface Motivo {
  id: number;
  descripcion: string;
}

@Component({
  selector: 'app-guardias-cierre-modal',
  templateUrl: './guardias-cierre-modal.component.html',
  styleUrls: ['./guardias-cierre-modal.component.css']
})
export class GuardiasCierreModalComponent {
  @Output() cerrarModal = new EventEmitter<void>();
  @Output() motivoSeleccionado = new EventEmitter<Motivo>();

  error = false;
  mensaje: string;
  motivos: Motivo[] = [];
  selectedMotivo: Motivo | null = null;
  descripcion: string;

  @Input() cierre: CierreGuardia | null = null;
  @Input() editar: boolean;

  constructor( 
    private cierreGuardiaService: CierresGuardiaService,
    private charactersService: CharactersService,
  ) { }

  ngOnInit(): void {
    this.getMotivosDeCierre();
    if (this.editar) {
      this.selectedMotivo = this.cierre.motivo;
      if (this.selectedMotivo.id !== 1){
        this.descripcion = '';
      } else {
        this.descripcion = this.cierre.motivoDescripcion;
      }
    } else {
      this.resetValues()
    }
  }

  cerrar() {
    this.cerrarModal.emit();
  }

  confirmar() {
    this.error = false;
    if (this.selectedMotivo) {
      if (this.selectedMotivo.id === 1){
        if (this.descripcion === ''){
          this.handleError('Por favor, ingrese una descripción válida.');
        } else {
          this.selectedMotivo.descripcion = this.descripcion;
        }
      }
      if (!this.error){
        this.motivoSeleccionado.emit(this.selectedMotivo);
        this.cerrar();
      }
    } else {
      this.handleError('Por favor, seleccione un motivo');
      }
  }

  async getMotivosDeCierre() {
    await this.cierreGuardiaService.getMotivosDeCierre().toPromise().then(
      data => {
        this.motivos = data.elementos;
      },
      error => {
        this.handleError("No se pueden recuperar los motivos en este momento. Intente más tarde");
      }
    );
  }

  handleError(mensaje:string){
    this.error = true;
    this.mensaje = mensaje;
  }

  resetValues(){
    this.descripcion = '';
    this.cierre = null;
    this.selectedMotivo = null;
  }
  sanitizeInput(): void {
    const invalidChars = /[&\\<>"']/g;
    this.descripcion = this.descripcion.replace(invalidChars, '');
  }
}
