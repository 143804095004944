import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class CharactersService {

  mapaEscapar = {
    '&': '&amp;',
    '"': '&quot;',
    '<': '&lt;',
    '\\': '&#92;', 
    '>': '&gt;', 
    "'": '&apos;' 
  };
  
  mapaReconvertir = {
    '&amp;': '&',
    '&quot;': '"',
    '&lt;': '<',
    '&#92;': '\\',
    '&gt;': '>',
    '&apos;': "'" 
  };
  
  
  regexRec = new RegExp(Object.keys(this.mapaReconvertir).join('|'), 'g');
  regexEsc = new RegExp(Object.keys(this.mapaEscapar).join('|'), 'g');

  constructor() {
        
  }
  
  escaparCaracteresEspeciales(texto) {
    if (texto || texto == '') {
      const mapa = this.mapaEscapar;
      let textoFiltrado = texto.replace(this.regexEsc, function(matched) {
        return mapa[matched];
      });

      return textoFiltrado;
    } else {
      return null;
    }
  }

  reconvertirCaracteresEspeciales(texto) {
    if (texto || texto == '') {
      const mapa = this.mapaReconvertir;
      let textoFiltrado = texto.replace(this.regexRec, function(matched) {
        return mapa[matched];
      });

      return textoFiltrado;
    } else {
      return null;
    }
  }
}
